<template>
    <b-modal scrollable :id="modalId" no-close-on-backdrop :title="modalTitle" 
    :size="modalSize" :redLink="link">
        <slot>
            <p class="my-4">Hello from modal!</p>
        </slot>
        <template #modal-footer>
            &nbsp;
        </template>
  </b-modal>
</template>

<style>
    @import url('../../node_modules/bootstrap/dist/css/bootstrap.css');
</style>

<script>
export default {
    name:"a-modal",
    props:{
        modalId:{
            type:String,
            required:true
        },
        modalTitle:{
            type:String,
            required:true
        },
        modalSize:{
            type:String,
        },
        link:{
            type:String
        }
    },

    mounted() {
        this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
        console.log('Modal is about to be shown', bvEvent, modalId)
        })
        this.$root.$on('bv::modal::close', (bvEvent, modalId) => {
        console.log('Modal is about to be closed', bvEvent, modalId)
        })
    },

    methods:{
        away(){
            alert()
        }
    }
}
</script>