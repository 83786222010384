import { render, staticRenderFns } from "./admin-reset-password.vue?vue&type=template&id=133e2fe7&"
import script from "./admin-reset-password.vue?vue&type=script&lang=js&"
export * from "./admin-reset-password.vue?vue&type=script&lang=js&"
import style0 from "./admin-reset-password.vue?vue&type=style&index=0&id=133e2fe7&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports