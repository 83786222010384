
export default {

    regPackages(state,data){
        state.regPackages = data
    },

    regPackage(state,data){
        state.regPackage = data
    }
}