
export default {

    regPackages(state){
        return state.regPackages
    },

    regPackage(state){
        return state.regPackage
    }
}