<template>
    <div id="">
    <main>
        <div class="p-t-b-100 height-full bg-green" style="background-image: url('assets/img/bg-wall2.png')">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 mx-md-auto">
                        <div class="text-white text-center ">
                            <img class="img-responsive" src="assets/img/logo-white.png" style= "max-width: 100%;padding-bottom:40px" alt="">
                            <img class="img-responsive" src="assets/img/protection.png" style= "max-width:100px;padding-bottom:10px" alt="">
                            <p class=" font-weight-lighter">Reset Password</p>
                        </div>
                        <form @submit.prevent="submit()">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group has-icon"><i class="icon-user"></i>
                                        <input required type="password" v-model="form.code" id="code-field" class="form-control form-control-lg no-b" placeholder="Code">
                                        <span @click="viewPass('code')" id="eye" class="green-text field-icon toggle-password mr-3 icon-eye3" style="text-decoration: none"></span>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group has-icon"><i class="icon-lock green-text"></i>								    
                                        <input type="password" v-model="form.password" id="password-field" class="form-control form-control-lg no-b" placeholder="Enter new password">
                                        <span @click="viewPass('pass')" id="eye" class="green-text field-icon toggle-password mr-3 icon-eye3" style="text-decoration: none"></span>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <span v-if="submitting" class="btn btn-success btn-lg btn-block">...</span>
                                    <input v-else type="submit" class="btn btn-success btn-lg btn-block" value="Submit">
                                </div>
                            </div>

                            <div class="col-lg-6 offset-4 mt-2 mb-2">
                                <span v-if="submitting" class="">...</span>
                                <a v-else class=" text-white" href="#" @click="sendCode()" style="text-decoration:none">
                                    <span class="font-weight-bold">Resend Confirmation Code</span>
                                </a>
                            </div>                                
                            <div class="col-md-12">
                                <div class="text-center">
                                    <img class="img-responsive text-center" src="assets/img/or.png" style= "max-width:250px;" alt="">
                                    <p class="text-white mt-3">
                                        <router-link :to="{name:'user-login'}" style="text-decoration:none"> <b>Proceed to login!</b></router-link>
                                    </p>
                                </div>
                            </div>

                        </form>
                        <!-- <div class="row">
                            <p class="forget-pass text-white text-center"> Login Instead ?
                                <br><router-link :to="{name:'user-login'}"> <b>Login</b> </router-link>
                            </p>
                        </div>  -->
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
</template>

<style>
    .field-icon {
    float: right;
    margin-left: -30px;
    margin-top: -35px;
    position: relative;
    z-index: 2;
    font-size: 20px;
}
</style>

<script>
import { notification } from '@/util/notification'
import {mapActions,mapState} from 'vuex'
export default {

    data(){
        return{
            form:{
                code:null,
                password:null,
                email: this.$route.query.email,
                user_type:'user'
            },

            reset_form:{
                email:this.$route.query.email,
                user_type:'user'
            },

            showPass:false
        }
    },

    computed:{
        ...mapState({
            submitting:state=>state.submitting
        })
    },
    
    methods:{
        ...mapActions('authStore',['resetPassword','resetPasswordLink']),

        submit(){
            if(!this.reset_form.email){
                notification.warning("Invalid email address, please return to previous page")
                return
            }
            this.resetPassword(this.form)
        },

        viewPass(field){
           let inp = field == 'pass' ? document.getElementById('password-field') : document.getElementById('code-field');
            this.showPass = !this.showPass
            if(this.showPass){
                inp.type = 'text'
            }else{
                inp.type = 'password'
            } 
        },

        sendCode(){
            if(!this.reset_form.email){
                notification.warning("Invalid email address, please return to previous page")
                return
            }
            this.resetPasswordLink(this.reset_form)
        }
    }
}
</script>