
export default {

    products(state){
        return state.products
    },

    product(state){
        return state.product
    },
}