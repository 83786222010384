
export default {

    incentives(state){
        return state.incentives
    },

    incentive(state){
        return state.incentive
    }
}