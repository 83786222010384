
export default {

    serviceProviders(state,data){
        state.serviceProviders = data
    },

    serviceProvider(state,data){
        state.serviceProvider = data
    }
}