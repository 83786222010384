
export default {

    incentives(state,data){
        state.incentives = data
    },

    incentive(state,data){
        state.incentive = data
    }
}