
export default {

    products(state,data){
        state.products = data
    },

    product(state,data){
        state.product = data
    }
}