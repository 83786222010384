
export default {

    incentiveClaims(state){
        return state.incentiveClaims
    },

    claims(state){
        return state.claims
    },

    currentIncentive(state){
        return state.currentIncentive
    }
}