
export default {

    withdrawals(state){
        return state.withdrawals
    },

    withdrawal(state){
        return state.withdrawal
    },

    userWithdrawals(state){
        return state.userWithdrawals
    },

    totalWithdrawals(state){
        return state.totalWithdrawals
    },

    userTotalWithdrawals(state){
        return state.userTotalWithdrawals
    },

    userPendingWithdrawals(state){
        return state.userPendingWithdrawals
    },

    
}