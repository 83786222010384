
export default {

    ranks(state){
        return state.ranks
    },

    rank(state){
        return state.rank
    },

    currentRankBadge(state){
        return state.currentRankBadge
    }
}