
export default {

    settings(state,data){
        state.settings = data
    },

    setting(state,data){
        state.setting = data
    },

    referralBonusSetting(state,data){
        state.referralBonusSetting = data
     }
}